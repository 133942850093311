<div class="tw-bg-olive-dark-3">
  <div class="content-wrapper tw-flex tw-flex-row tw-items-stretch tw-justify-between max-d:tw-flex-wrap d:tw-gap-x-30">
    <div class="tw-order-1 tw-py-[13px] max-d:tw-w-[calc(100%-80px)] d:tw-order-1 d:tw-flex-grow d:tw-py-15">
      <h3 class="tw-text-xl tw-font-bold tw-leading-xl d:tw-text-[36px] d:tw-leading-[46.8px]">
        {{ userBannerData().firstName }}
        {{ userBannerData().lastName }}
        {{ userBannerData().rankShort | translateObject }}
        {{ userBannerData().rankAddition | safeTranslate }}
      </h3>
      @if (userBannerData().rankShort) {
        <p class="msa-text">{{ userBannerData().division | translateObject }}</p>
      }
    </div>
    <div class="tw-order-2 tw-grid tw-w-[80px] tw-place-content-center tw-bg-olive-dark-2 d:tw-order-4 d:tw-w-[110px]">
      <mat-icon
        class="!tw-size-60 d:!tw-size-[80px]"
        [svgIcon]="'avatar_' + userBannerData().gender"
        (click)="easterEggManager.toggleFleeingButton()"
      />
    </div>
    @if (userBannerData().militaryFunction) {
      <div
        class="tw-order-4 tw-flex tw-items-center tw-gap-x-6 max-d:tw-w-1/2 max-d:tw-px-15 max-d:tw-py-12 d:tw-order-2 d:tw-gap-x-9"
      >
        @if (userBannerData().troopIcon) {
          <mat-icon class="!tw-h-auto !tw-w-30 d:!tw-w-[36px]" [svgIcon]="userBannerData().troopIcon!" />
        }
        <span class="msa-text-small">
          {{ userBannerData().militaryFunction | translateObject }}
        </span>
      </div>
    }
    @if (userBannerData().maxDutyDay) {
      <div
        class="tw-order-5 tw-flex tw-items-center max-d:tw-w-1/2 max-d:tw-px-15 max-d:tw-py-12 d:tw-order-3 d:tw-w-[243.5px]"
      >
        <div class="msa-text-small d:tw-gap-y6 tw-flex tw-w-full tw-flex-col tw-gap-y-3">
          <p class="tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap">
            {{ userBannerData().currentDutyDay }} {{ "i18n.duty-days-component.day-container" | safeTranslate }}
            {{ userBannerData().maxDutyDay }}
          </p>
          <div class="tw-relative tw-h-3 tw-w-full tw-bg-olive-dark-1">
            <div class="tw-absolute tw-bottom-1 tw-left-0 tw-right-0 tw-top-1 tw-bg-olive-dark-1"></div>
            <div
              class="tw-absolute tw-left-0 tw-h-3 tw-bg-white"
              [style]="
                'width:' + getProgressBarValue(userBannerData().currentDutyDay, userBannerData().maxDutyDay ?? 0) + '%'
              "
            ></div>
          </div>
          <p class="tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap">
            {{ "i18n.duty-days-component.info-label" | safeTranslate }}
          </p>
        </div>
      </div>
    }
    <hr class="tw-order-3 tw-w-full tw-border tw-border-olive-dark-4 tw-text-opacity-100 d:tw-hidden" />
  </div>
</div>
