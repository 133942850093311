import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, inject, OnInit} from '@angular/core';
import {MatBadgeModule} from '@angular/material/badge';
import {MatIconModule} from '@angular/material/icon';
import {RouterLink} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {NotificationsStateSelectors} from '@shared/shared-module/stores/selectors/notifications.state.selectors';
import {readStoreSignal} from '@shared/shared-module/utils/store.utils';
import {NotificationRestService} from 'projects/notifications/src/app/core/api/generated/msa-notification';
import {Store} from '@ngxs/store';
import {FetchUnreadNotificationCount} from '@shared/shared-module/stores/actions/notifications.state.actions';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-notification-bell',
  standalone: true,
  providers: [NotificationRestService],
  imports: [CommonModule, MatIconModule, TranslateModule, RouterLink, SafeTranslatePipe, MatBadgeModule],
  templateUrl: './notification-bell.component.html'
})
export class NotificationBellComponent implements OnInit {
  public countUnread = readStoreSignal(NotificationsStateSelectors.slices.unreadCount);
  public badgeHidden = computed(() => this.countUnread() === 0);
  private store = inject(Store);

  ngOnInit() {
    this.store.dispatch(new FetchUnreadNotificationCount());
  }
}
