<app-top-bar />

@if (!hideNavigationAndUserBar()) {
  <app-navigation-menu [navigationMenuData]="navigationMenuData()" />

  @if (userBannerData(); as userData) {
    <msa-user-banner [userBannerData]="userData" />
  }
}

<ng-template #noPersonData>
  <!-- not logged in (landing page) -->
  <app-top-bar />

  @if (!hideNavigationAndUserBar()) {
    <app-navigation-menu [navigationMenuData]="navigationMenuData()" />
  }
</ng-template>
