import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {Store} from '@ngxs/store';

import {CommonModule, DOCUMENT} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {readStoreSignal} from '@shared/shared-module/utils/store.utils';
import {Language} from '../../../../../projects/admin-query/src/app/core/api/generated/msa-admin-query';
import {ChangeLanguage} from '../../stores/actions/app.state.action';
import {AppStateSelectors} from '../../stores/selectors/app.state.selectors';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslateModule, MatIconModule, MatMenuModule, MatIconModule, MatButtonModule]
})
export class LanguageSelectorComponent {
  private store = inject(Store);
  private document = inject(DOCUMENT);
  public translate = inject(TranslateService);
  currentLanguage = readStoreSignal(AppStateSelectors.slices.language);

  changeLanguage(lang: string): void {
    this.store.dispatch(new ChangeLanguage(lang as Language));
    this.document.documentElement.lang = lang;
  }
}
