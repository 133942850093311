<div class="footer-block tw-relative tw-inline-block tw-w-full">
  <div class="content-wrapper">
    <ng-content />
  </div>

  @if (!hideSimpelContactFooter()) {
    <!-- Custom Footer: can be removed once we can report errors -->
    <div
      class="msa-text-small tw-grid tw-gap-[36px] tw-border-t tw-border-t-black-light-4 tw-px-15 tw-py-30 tw-text-black-light-5 d:tw-grid-cols-6 d:tw-gap-0 d:tw-px-60 d:tw-pb-60 d:tw-pt-90"
    >
      <div class="tw-flex tw-w-fit tw-flex-col tw-gap-12">
        <p class="tw-font-medium tw-text-white" safeTranslate="i18n.common.contact"></p>
        <a class="tw-text-black-light-5" routerLink="/contact" safeTranslate="i18n.common.contactForm"></a>
      </div>

      <div class="tw-flex tw-w-fit tw-flex-col tw-gap-12">
        <p class="tw-font-medium tw-text-white" safeTranslate="i18n.common.social-media.title"></p>
        @switch (language()) {
          @case ("de") {
            <a
              href="https://www.nlt.admin.ch/f/s.aspx?15330B86741542EC952535939CF8420932"
              target="_blank"
              safeTranslate="i18n.footer.subscribeNewsletter"
            ></a>
          }
          @case ("fr") {
            <a
              href="https://www.nlt.admin.ch/f/s.aspx?197747434A79863852EB99D7D8E65645F8"
              target="_blank"
              safeTranslate="i18n.footer.subscribeNewsletter"
            ></a>
          }
          @case ("it") {
            <a
              href="https://www.nlt.admin.ch/f/s.aspx?197962EAB1D988530962F9E9630356607E"
              target="_blank"
              safeTranslate="i18n.footer.subscribeNewsletter"
            ></a>
          }
        }
      </div>
    </div>
  }

  <div class="tw-bg-black-light-1 tw-px-15 tw-py-30 tw-align-middle d:tw-px-60 d:tw-py-30">
    <div class="content-wrapper tw-grid tw-grid-cols-1 tw-gap-12 d:tw-grid-cols-2">
      <p class="msa-text-small tw-text-black-light-5.5" [innerHtml]="'i18n.common.legal-notice' | safeTranslate"></p>
      <p class="msa-text-small d:tw-text-end">
        {{ currentDate | date: "yyyy" }} {{ "i18n.application.title" | safeTranslate }}
      </p>
    </div>
  </div>
</div>
