import {CommonModule} from '@angular/common';
import {HttpStatusCode} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, computed, inject} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {Store} from '@ngxs/store';
import {AuthenticationService} from '@shared/core/services/authentication.service';
import {LanguageSelectorComponent} from '@shared/shared-module/components/language-selector/language-selector.component';
import {NotificationBellComponent} from '@shared/shared-module/components/notification-bell/notification-bell.component';
import {FeatureFlagDirective} from '@shared/shared-module/directives/feature-flag.directive';
import {MsaIconModule} from '@shared/shared-module/msa-icon.module';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {PersonDataStateSelectors} from '@shared/shared-module/stores/selectors/person-data.state.selectors';
import {first, last} from 'lodash';
import {map} from 'rxjs';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    LanguageSelectorComponent,
    RouterModule,
    MatIconModule,
    MsaIconModule,
    MatMenuModule,
    TranslateModule,
    NotificationBellComponent,
    SafeTranslatePipe,
    FeatureFlagDirective
  ],
  standalone: true
})
export class TopBarComponent {
  private store = inject(Store);

  private authenticationService = inject(AuthenticationService);
  public userName = computed(() => this.authenticationService.userDetails()?.name ?? '');
  public initials = computed(() => {
    const names = this.userName().split(' ');
    return [first(names)?.[0] ?? '', last(names)?.[0] ?? ''].join('');
  });

  public isLoggedIn = toSignal(this.authenticationService.isAuthenticated(), {initialValue: false});

  hasNoPersonData$ = this.store
    .select(PersonDataStateSelectors.slices.statusCode)
    .pipe(map(statusCode => statusCode === HttpStatusCode.NoContent));

  logout() {
    this.authenticationService.logout();
  }
}
